<template>
  <TestingConsole :playerContext="playerContext" :lastSubmission="lastSubmission" @context-update="updateContext" />
  <Game :player-context="playerContext" @submit="submit" @new-game="newGame" @main-page="mainPage" />
</template>

<script>
import Game from "./Game";
import TestingConsole from "./TestingConsole";
import { PlayerContext, RoundContext, RoundType } from "./proto/game_pb";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";

export default {
  name: "Room",
  components: { TestingConsole, Game },
  emits: ["newGame", "mainPage"],
  data: () => ({
    playerContext: null,
    lastSubmission: null,
  }),
  methods: {
    newGame() {
      this.$emit("newGame");
    },
    mainPage() {
      this.$emit("mainPage");
    },
    updateContext(newPlayerContext) {
      this.playerContext = newPlayerContext;
    },
    submit(submission) {
      this.lastSubmission = submission;
    },
  },
  mounted() {
    const pc = new PlayerContext();
    pc.setRoomId("roomless");
    pc.setNumRounds(3);
    pc.setSlot(5);
    const playersMap = pc.getPlayersMap();
    playersMap.set(2, 2);
    playersMap.set(5, 1);
    playersMap.set(6, 6);
    // playersMap.set(1, 7);
    // playersMap.set(3, 3);
    // playersMap.set(4, 4);
    const round = new RoundContext();
    round.setRoundNumber(4);
    round.setRoundType(RoundType.TEXT);
    round.setChoicesList([
      "Dance",
      "Cry",
      "Yell",
      "Do Nothing",
    ]);
    round.setPrompt("A friend sends you explicit photos of a classmate on social media");
    // Test max 255 character prompt
    // round.setPrompt("Lorem ipsum dolor sit amet, nonummy ligula volutpat hac integer nonummy. Suspendisse ultricies, congue etiam tellus, erat libero, nulla eleifend, mauris pellentesque. Suspendisse integer praesent vel, integer gravida mauris, fringilla vehicula lacinia non");
    round.setCategory("Boundary Setting and Consent");
    round.setJudgeSlot(5);
    // round.getSubmissionsMap().set(2, "Phone a friend");
    // round.getSubmissionsMap().set(5, "High, Low, Buffalo");
    // round.getSubmissionsMap().set(6, "High, Low, Buffalo");
    // round.getSubmissionsMap().set(1, "High, Low, Buffalo");
    // round.getSubmissionsMap().set(4, "High, Low, Buffalo");
    // round.getSubmissionsMap().set(3, "High, Low, Buffalo");
    // round.getSubmissionsMap().set(2, "https://media.giphy.com/media/aZ3LDBs1ExsE8/giphy.gif");
    // round.getSubmissionsMap().set(6, "https://media.giphy.com/media/2wSaulb0fsDydh0IoB/giphy-downsized-large.gif");
    // round.getSubmissionsMap().set(5, "https://media.giphy.com/media/aZ3LDBs1ExsE8/giphy.gif");
    // round.getSubmissionsMap().set(1, "https://media.giphy.com/media/aZ3LDBs1ExsE8/giphy.gif");
    // round.getSubmissionsMap().set(4, "https://media.giphy.com/media/2wSaulb0fsDydh0IoB/giphy-downsized-large.gif");
    // round.getSubmissionsMap().set(3, "https://media.giphy.com/media/2wSaulb0fsDydh0IoB/giphy-downsized-large.gif");
    round.setHottip("Wash your hands before eating");
    const winnersMap = pc.getRoundWinnersMap();
    winnersMap.set(1, 6);
    const now = new Timestamp();
    now.fromDate(new Date());
    round.setRoundStart(now);
    round.setJudgeStart(undefined);
    round.setRoundEnd(undefined);
    pc.setCurrentRound(round);
    this.playerContext = pc;
  },
};
</script>

<style scoped>

</style>
