<template>
  <div class="main" v-show="showDebug && playerContext" @keyup="sniff">
    <h3>☣︎ TESTING CONSOLE ☣︎</h3>
    <div>
      <div class="box" v-if="playerContext">
        <div class="col-2">
          <h4>Game</h4>
          <ul>
            <li>
              roomId: {{ playerContext.getRoomId() }}
              <input v-model="newContext.roomId" />
            </li>
            <li>
              Active Players:
              <ul>
                <li v-for="tuple in playerContext.getPlayersMap().toObject()" :key="tuple[0]" >
                  Slot {{tuple[0]}} &mdash; Avatar {{tuple[1]}}
                </li>
              </ul>
            </li>
            <li>
              You are: {{ playerContext.getSlot() }}
              <span style="white-space: nowrap">
                <button type="button" @click="newContext.slot -= 1">
                  &DoubleLeftArrow;
                </button>
                {{ newContext.slot }}
                <button type="button" @click="newContext.slot += 1">
                  &DoubleRightArrow;
                </button>
              </span>
            </li>
            <li>
              roundWinnersMap:
              {{
                JSON.stringify(playerContext.getRoundWinnersMap().toObject())
              }}
            </li>
            <li>
              numRounds: {{ playerContext.getNumRounds() }}
              <span style="white-space: nowrap">
                <button type="button" @click="newContext.numRounds -= 1">
                  &DoubleLeftArrow;
                </button>
                {{ newContext.numRounds }}
                <button type="button" @click="newContext.numRounds += 1">
                  &DoubleRightArrow;
                </button>
              </span>
            </li>
          </ul>
        </div>
        <div class="col-2">
          <h4>Round</h4>
          <ul>
            <li>
              Round Number:
              {{ playerContext.getCurrentRound().getRoundNumber() }}
              <span style="white-space: nowrap">
                <button
                  type="button"
                  @click="newContext.currentRound.roundNumber -= 1"
                >
                  &DoubleLeftArrow;
                </button>
                {{ newContext.currentRound.roundNumber }}
                <button
                  type="button"
                  @click="newContext.currentRound.roundNumber += 1"
                >
                  &DoubleRightArrow;
                </button>
              </span>
            </li>
            <li>
              Round Type: {{ playerContext.getCurrentRound().getRoundType() }}
              <span style="white-space: nowrap">
                <button
                  type="button"
                  :class="{
                    selected: newContext.currentRound.roundType == 0,
                  }"
                  @click="newContext.currentRound.roundType = 0"
                >
                  TEXT
                </button>
                <button
                  type="button"
                  :class="{
                    selected: newContext.currentRound.roundType == 1,
                  }"
                  @click="newContext.currentRound.roundType = 1"
                >
                  GIF
                </button>
              </span>
            </li>
            <li>
              Judge: {{ playerContext.getCurrentRound().getJudgeSlot() }}
              <span style="white-space: nowrap">
                <button
                  type="button"
                  @click="newContext.currentRound.judgeSlot -= 1"
                >
                  &DoubleLeftArrow;
                </button>
                {{ newContext.currentRound.judgeSlot }}
                <button
                  type="button"
                  @click="newContext.currentRound.judgeSlot += 1"
                >
                  &DoubleRightArrow;
                </button>
              </span>
            </li>
            <li>Prompt: {{ playerContext.getCurrentRound().getPrompt() }}</li>
            <li>Category: {{ playerContext.getCurrentRound().getCategory() }}</li>
            <li>
              Submissions:
              <pre>{{
                JSON.stringify(
                  playerContext
                    .getCurrentRound()
                    .getSubmissionsMap()
                    .toObject()
                )
              }}</pre>
            </li>
            <li>
              Round Start:
              {{ playerContext.getCurrentRound().getRoundStart() && playerContext.getCurrentRound().getRoundStart().toDate() }}
            </li>
            <li>
              Judge Start:
              {{
                playerContext.getCurrentRound().getJudgeStart() && playerContext.getCurrentRound().getJudgeStart().toDate() ||
                "null"
              }}
            </li>
            <li>
              Round End:
              {{
                playerContext.getCurrentRound().getRoundEnd() && playerContext.getCurrentRound().getRoundEnd().toDate() ||
                "null"
              }}
            </li>
            <li>
              Last Submission: {{ jsonSubmission }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="cta">
      <button type="button" @click="doUpdate">UPDATE</button>
    </div>
  </div>
</template>

<script>
import { PlayerContext, RoundContext } from "./proto/game_pb";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
export default {
  name: "TestingConsole",
  emits: ["contextUpdate"],
  props: {
    playerContext: Object,
    lastSubmission: Object,
  },
  data: () => ({
    newContext: {},
    newRoundContext: {},
    showDebug: false,
  }),
  computed: {
    jsonSubmission() {
      if (!this.lastSubmission) {
        return "";
      }
      return JSON.stringify(this.lastSubmission.toObject(), null, 2);
    },
    jsonContext() {
      if (!this.playerContext) {
        return "";
      }
      return JSON.stringify(this.playerContext.toObject(), null, 2);
    },
    jsonRoundContext() {
      if (!this.playerContext || !this.playerContext.getCurrentRound()) {
        return "";
      }
      return JSON.stringify(
        this.playerContext.getCurrentRound().toObject(),
        null,
        2
      );
    },
  },
  watch: {
    playerContext(updatedContext) {
      if (!updatedContext) {
        this.newContext = undefined;
        this.newRoundContext = undefined;
        return;
      }
      this.newContext = updatedContext.toObject();
      if (updatedContext.getCurrentRound()) {
        this.newRoundContext = updatedContext.getCurrentRound().toObject();
      }
    },
  },
  methods: {
    sniff(event) {
      console.log("KEY EVENT!", event);
    },
    doUpdate() {
      const pc = new PlayerContext();
      const updatedContext = this.newContext;
      pc.setRoomId(updatedContext.roomId);
      pc.setNumRounds(updatedContext.numRounds);
      pc.setSlot(updatedContext.slot);
      if (updatedContext.playersMap) {
        updatedContext.playersMap.forEach((pair) =>
            pc.getPlayersMap().set(pair[0], pair[1])
        );
      }
      if (updatedContext.roundWinnersMap) {
        updatedContext.roundWinnersMap.forEach((pair) =>
          pc.getRoundWinnersMap().set(pair[0], pair[1])
        );
      }
      if (updatedContext.currentRound.roundEnd) {
        const end = new Timestamp();
        end.setSeconds(updatedContext.currentRound.roundEnd.seconds);
        end.setNanos(updatedContext.currentRound.roundEnd.nanos);
        round.setRoundStart(end);
      }
      const round = new RoundContext();
      round.setRoundNumber(updatedContext.currentRound.roundNumber);
      round.setRoundType(updatedContext.currentRound.roundType);
      round.setChoicesList(updatedContext.currentRound.choicesList);
      round.setPrompt(updatedContext.currentRound.prompt);
      round.setCategory(updatedContext.currentRound.category);
      round.setJudgeSlot(updatedContext.currentRound.judgeSlot);
      if (updatedContext.currentRound.roundStart) {
        const start = new Timestamp();
        start.setSeconds(updatedContext.currentRound.roundStart.seconds);
        start.setNanos(updatedContext.currentRound.roundStart.nanos);
        round.setRoundStart(start);
      }
      if (updatedContext.currentRound.judgeStart) {
        const start = new Timestamp();
        start.setSeconds(updatedContext.currentRound.judgeStart.seconds);
        start.setNanos(updatedContext.currentRound.judgeStart.nanos);
        round.setJudgeStart(start);
      }
      if (updatedContext.currentRound.roundEnd) {
        const end = new Timestamp();
        end.setSeconds(updatedContext.currentRound.roundEnd.seconds);
        end.setNanos(updatedContext.currentRound.roundEnd.nanos);
        round.setRoundStart(end);
      }
      if (updatedContext.currentRound.submissionsMap) {
        updatedContext.currentRound.submissionsMap.forEach((pair) =>
          round.getSubmissionsMap().set(pair[0], pair[1])
        );
      }
      pc.setCurrentRound(round);
      this.$emit("contextUpdate", pc);
      this.showDebug = false;
    },
  },
  mounted() {
    this.handler = (e) => {
      if (e.key == "~" || e.key == "`") {
        this.showDebug = !this.showDebug;
      }
    };
    window.addEventListener("keydown", this.handler);
  },
  beforeUnmount() {
    window.removeEventListener("keydown", this.handler);
  },
};
</script>

<style lang="scss" scoped>
.main {
  font-family: "Operator Mono", "Fira Code", Inconsolata, Menlo, monospace;
  padding: 1em;
  background-color: palegoldenrod;
  border-bottom: thick solid brown;
}

h3,
h4 {
  font-weight: bold;
  text-align: center;
}

h3 {
  font-size: 24px;
  margin-bottom: 1em;
}
.box {
  display: flex;
}
.col-2 {
  flex: 1;
}
.col-2 textarea {
  height: 100%;
  width: 100%;
}
.col-2 ul li {
  border: thin solid cornflowerblue;
  margin: 0.5em;
  padding: 0.5em;
}
button {
  padding: 1em;
  margin: 5px;
  border-radius: 10px;
  border: thin solid #42b983;
  background-color: #42b983;
  &.selected {
    background-color: aquamarine;
    &:hover {
      background-color: #42b983;
    }
  }
  &:hover {
    color: white;
    background-color: #0f7462;
  }
  &:active {
    color: white;
    background-color: #42b983;
    border-color: #0f7462;
  }
}
.cta {
  margin-top: 2em;
  text-align: center;
}
</style>
